import { useClerk, UserButton, useUser } from "@clerk/nextjs"
import { useRouter } from "next/router"
import Link from "next/link"
import { Fragment, useEffect, useState } from "react"
import clsx from "clsx"
import BurgerSVG from "vector/icons/burger.svg"
import CaretIconSVG from "vector/icons/angle-bracket.svg"
import CloseSVG from "vector/icons/close-icon.svg"
import LogoSVG from "vector/logo.svg"
import styles from "./Header.module.css"
import { Button } from "./atoms"

type Props = {
  theme: "white" | "black"
}

function Header({ theme }: Props) {
  const [mobileMenuIsExpanded, setMobileMenuIsExpanded] = useState(false)
  const [activeMenu, setActiveMenu] = useState(null)

  const { isLoaded, user } = useUser()
  const clerk = useClerk()

  const router = useRouter()

  const isNavItemActive = (path: string) => {
    if (router.pathname === "/for-employees" || router.pathname === "/about" || router.pathname === "/for-issuers") {
      return router.pathname.includes(path)
    }

    if (path === "/resources") {
      return (
        router.pathname.includes(path) ||
        router.pathname.includes("/guides") ||
        router.pathname.includes("/articles") ||
        router.pathname.includes("/rsu-rankings") ||
        router.pathname.includes("/offers") ||
        router.pathname.includes("/newsletter")
      )
    }
  }

  useEffect(() => {
    if (mobileMenuIsExpanded) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }

    return () => {
      document.body.classList.remove("no-scroll")
    }
  }, [mobileMenuIsExpanded])

  return (
    <>
      <header className={clsx(styles.header, styles[theme], mobileMenuIsExpanded && styles.headerExpanded)}>
        <div className="widecontainer">
          <div className={styles.headerLogo}>
            <Link href={"/for-issuers"} className={styles.logo}>
              <LogoSVG />
            </Link>

            <div className={styles.mobileNavMenu}>
              {mobileMenuIsExpanded ? (
                <CloseSVG
                  className={`${styles.mobileNavMenuButton} ${styles.closeIcon}`}
                  onClick={() => setMobileMenuIsExpanded(false)}
                />
              ) : (
                <BurgerSVG className={`${styles.mobileNavMenuButton}`} onClick={() => setMobileMenuIsExpanded(true)} />
              )}
              {user && <UserButton />}
            </div>
          </div>

          <div className={clsx(styles.headerContent, mobileMenuIsExpanded && styles.expanded)}>
            <div className={styles.desktopNavContent}>
              <span
                className={clsx(styles.menuCTALink, isNavItemActive("/for-issuers") && styles.navItemActive)}
                onClick={() => {
                  router.push("/for-issuers")
                }}
                data-cy="header-home"
              >
                For issuers
              </span>
              <span
                className={clsx(styles.menuCTALink, isNavItemActive("/for-employees") && styles.navItemActive)}
                onClick={() => {
                  router.push("/for-employees")
                }}
                data-cy="header-for-employees"
              >
                For employees
              </span>
              <div
                className={clsx(
                  activeMenu === "resources" && styles.active,
                  isNavItemActive("/resources") && styles.navItemActive,
                  styles.navMenuTitleContainer
                )}
                onMouseEnter={() => setActiveMenu("resources")}
                onMouseLeave={() => setActiveMenu(null)}
                onClick={() => setActiveMenu(activeMenu ? null : "resources")}
              >
                <span className={clsx(styles.navMenuTitle, isNavItemActive("/resources") && styles.navItemActive)}>
                  Resources <CaretIconSVG className={styles.caret} />
                </span>
                <div
                  className={`${styles.navMenuItemSubmenu} ${styles.condensed}`}
                  style={{ minWidth: 225 }}
                  onClick={() => {
                    setMobileMenuIsExpanded(false)
                  }}
                >
                  <Link href="/rsu-rankings">RSU rankings</Link>
                  <Link href="/offers">Offer review</Link>
                  <Link href="/guides/rsu-compensation">RSU guide</Link>
                  <Link href="/guides/salary-negotiation">Salary negotiation guide</Link>
                  <Link href="/guides">More guides</Link>
                  <Link href="/articles">Knowledge library</Link>
                  <Link href="/newsletter">Join the newsletter</Link>
                </div>
              </div>
              <span
                className={clsx(styles.menuCTALink, isNavItemActive("/about") && styles.navItemActive)}
                onClick={() => {
                  router.push("/about")
                }}
                data-cy="header-about"
              >
                About
              </span>
            </div>

            {mobileMenuIsExpanded && (
              <div className={styles.navtopLevelMenu}>
                {router.pathname !== "/for-issuers" && <Link href="/for-issuers">For issuers</Link>}
                {router.pathname !== "/for-employees" && <Link href="/for-employees">For employees</Link>}
                <Fragment>
                  <nav className={styles.navMenu}>
                    <Fragment>
                      <div
                        className={activeMenu === "resources" ? styles.active : null}
                        onMouseEnter={() => setActiveMenu("resources")}
                        onMouseLeave={() => setActiveMenu(null)}
                        onClick={() => setActiveMenu(activeMenu ? null : "resources")}
                      >
                        <span className={styles.navMenuTitle}>
                          Resources <CaretIconSVG className={styles.caret} />
                        </span>
                        <div
                          className={`${styles.navMenuItemSubmenu} ${styles.condensed}`}
                          style={{ minWidth: 225 }}
                          onClick={() => {
                            setMobileMenuIsExpanded(false)
                          }}
                        >
                          <Link href="/rsu-rankings">RSU rankings</Link>
                          <Link href="/offers">Offer review</Link>
                          <Link href="/guides/rsu-compensation">RSU guide</Link>
                          <Link href="/guides/salary-negotiation">Salary negotiation guide</Link>
                          <Link href="/guides">More guides</Link>
                          <Link href="/articles">Knowledge library</Link>
                          <Link href="/newsletter">Join the newsletter</Link>
                        </div>
                      </div>
                      {router.pathname !== "/about" && <Link href="/about">About</Link>}
                    </Fragment>
                  </nav>
                </Fragment>
              </div>
            )}

            {isLoaded ? (
              <div className={clsx(styles.menuCTA, user && styles.loggedIn)}>
                {user ? (
                  <>
                    {user.publicMetadata.user_role === "admin" && (
                      <Link href="/sofia" className={styles.menuCTAButton}>
                        Sofia
                      </Link>
                    )}
                    {user.publicMetadata.user_role === "issuer" && (
                      <Link href="/issuer" className={styles.menuCTAButton}>
                        <span data-cy="issuer-dashboard-navigation">Issuer dashboard →</span>
                      </Link>
                    )}
                    {user.publicMetadata.user_role === "partner" && (
                      <Link href="/partners" className={styles.menuCTAButton}>
                        <span data-cy="partner-dashboard-navigation">Partner dashboard →</span>
                      </Link>
                    )}
                    {user.publicMetadata.wealth_onboarded_at && !user?.publicMetadata?.wealth_closed_at && (
                      <Link href="/dashboard" className={styles.menuCTAButton} data-cy="client-dashboard-navigation">
                        Dashboard →
                      </Link>
                    )}
                    {user.publicMetadata.partner_managed_user && !user.publicMetadata.wealth_onboarded_at && (
                      <Link href="/start" className={styles.menuCTAButton}>
                        Get started →
                      </Link>
                    )}
                    <UserButton />
                  </>
                ) : (
                  <>
                    <span
                      className={styles.menuCTALink}
                      onClick={() => clerk.redirectToSignIn({ redirectUrl: window.location.href })}
                      data-cy="header-login"
                    >
                      Log in
                    </span>
                    <Button
                      title="Get a demo ->"
                      type="primary"
                      shape="square"
                      size="default"
                      className={styles.demoButton}
                      onClick={() => {
                        router.push("/get-a-demo")
                      }}
                    />
                  </>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
