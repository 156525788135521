import clsx from "clsx"
import styles from "./RadioInput2.module.css"

type Props = {
  value: boolean
  onClick: () => void
  className?: string
  name?: string
  id?: string
  disabled?: boolean
  title?: string
  dataCy?: string
}

export const RadioInput2 = ({
  value,
  onClick,
  className,
  name = "radio",
  id,
  disabled = false,
  title,
  dataCy,
  ...props
}: Props) => {
  return (
    <label
      className={clsx(styles.radioContainer, disabled && styles.radioContainerDisabled, className)}
      htmlFor={id}
      data-cy={dataCy}
    >
      <input type="radio" name={name} onChange={onClick} checked={value} disabled={!!disabled} id={id} {...props} />
      <span className={styles.checkMark}></span>
      <span className={styles.title}>{title}</span>
    </label>
  )
}
