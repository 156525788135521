import clsx from "clsx"
import React, { ReactNode } from "react"
import styles from "./Space.module.css"

interface Props {
  align?: "start" | "end" | "center" | "baseline" | "stretch"
  justify?: "start" | "end" | "center" | "baseline" | "stretch" | "space-between" | "space-around"
  direction?: "column" | "row" | "column-reverse" | "row-reverse"
  gap?: number
  minHeight?: number | "initial"
  children: ReactNode | ReactNode[]
  split?: boolean
  splitStyle?: string
  wrapItems?: number
  flexGrow?: number
  className?: string
  onClick?: () => void
}

export const Space = ({
  align = "stretch",
  justify = "stretch",
  direction = "column",
  gap = 25,
  minHeight = "initial",
  split,
  splitStyle,
  children,
  flexGrow = 0,
  className = "",
  wrapItems,
  onClick,
}: Props) => {
  let content = children

  if (split && Array.isArray(children)) {
    const tempContent = []
    children.forEach((child, idx) => {
      tempContent.push(child)
      if (idx !== children.length - 1) {
        tempContent.push(<hr className={`${styles.separator} ${splitStyle}`} key={idx} />)
      }
    })
    content = tempContent
  }
  const customBasis = `calc(${100 / wrapItems}% - ${gap ?? 25}px)`
  return (
    <div
      className={clsx(styles.container, split && styles.split, className, wrapItems && styles.wrapChildren)}
      style={
        {
          flexDirection: direction,
          gap,
          alignItems: align,
          justifyContent: justify,
          minHeight,
          flexBasis: 0,
          flex: flexGrow,
          flexWrap: wrapItems ? "wrap" : "nowrap",
          "--custom-basis": wrapItems ? customBasis : "",
        } as React.CSSProperties
      }
      onClick={onClick}
    >
      {content}
    </div>
  )
}
