import React from "react"
import clsx from "clsx"
import styles from "./Badge.module.css"
import InfoIcon from "../../../../vector/icons/info.svg"

interface Props {
  title: string
  color: "warning" | "green" | "error" | "grey" | "lightGrey" | "yellow" | "tertiary-green" | "orange"
  hoverInfo?: string
  rounded?: boolean
  className?: string
  upperCase?: boolean
  bold?: boolean
}

export const Badge = ({ title, color, hoverInfo, rounded, className, upperCase = true, bold = true }: Props) => (
  <div
    className={clsx(styles.container, upperCase && styles.uppercase, bold && styles.bold, className)}
    data-cy={`badge-${title}`}
  >
    {hoverInfo && <div className={styles.popup}>{hoverInfo}</div>}
    <div className={clsx(styles.pill, styles[color], rounded && styles.rounded)}>
      {title} {hoverInfo && <InfoIcon />}
    </div>
  </div>
)
