import Link from "next/link"

import styles from "./FooterDisclosures.module.css"

export default function FooterDisclosures({ hideSidebar = false }) {
  return (
    <div className={styles.disclosures}>
      {!hideSidebar && (
        <div className={styles.disclosuresSidebar}>
          Advisory services provided by Candor Financial LLC, an SEC registered investment adviser. Brokerage services
          provided to clients of Candor Financial LLC by Apex Clearing, an SEC registered broker-dealer and member of
          FINRA/SIPC.
          <div className={styles.disclosuresSidebarCopyright}>
            ©{new Date().getFullYear()} Candor Inc.
            <div>
              <Link href="/legal/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link href="/legal/terms" target="_blank" rel="noopener noreferrer">
                Terms
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className={styles.disclosuresBody}>
        <div className={styles.disclosuresBodyEmphasis}>
          Investments in securities: Not FDIC Insured • No Bank Guarantee • May Lose Value
        </div>
        <p>
          Investing in securities involves risks and there is always the potential of losing money when you invest in
          securities. Before investing, consider your investment objectives and Candor Financial LLC's charges and
          expenses. Candor Financial LLC's internet-based advisory services are designed to assist clients in achieving
          discrete financial goals. They are not intended to provide comprehensive tax advice or financial planning with
          respect to every aspect of a client's financial situation and do not incorporate specific investments that
          clients hold elsewhere. For more details, see{" "}
          <Link href="/documents/form-crs.pdf" target="_blank">
            Form CRS
          </Link>
          {" ("}
          <Link href="/legal/conversation-starters" target="_blank">
            conversation starters
          </Link>
          {") and "}
          <Link href="/documents/wrapfee-brochure.pdf" target="_blank">
            Wrap Fee Program Brochure
          </Link>
          .
        </p>

        <p>
          Past performance does not guarantee future results and the likelihood of investment outcomes are speculative
          in nature. Not an offer, solicitation of an offer, or advice to buy or sell securities in jurisdictions where
          Candor Financial LLC is not registered.
        </p>
      </div>
    </div>
  )
}
