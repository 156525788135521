import React from "react"
import styles from "./Chip.module.css"

interface Props {
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  activeValue?: string
  handleFilterChange?: ({ name, value }) => void
  filterName?: string
}

export const Chip = ({ activeValue, label, value, handleFilterChange, filterName }: Props) => {
  return (
    <div
      className={`${styles.filterChip} ${activeValue === value ? styles.activeChip : ""} `}
      key={value}
      onClick={() => {
        handleFilterChange && handleFilterChange({ name: filterName, value: value })
      }}
    >
      {" "}
      {label}
    </div>
  )
}
