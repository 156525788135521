import Link from "next/link"

import EmailForm from "./EmailForm"
import FooterDisclosures from "./FooterDisclosures"
import styles from "./Footer.module.css"
import ArticleList from "../articles/ArticleList"

import trendingArticles from "../../public/trendingArticles.json"

export default function Footer({ className = "" }) {
  return (
    <footer className={`${styles.footer} ${className}`}>
      <div className="dark widecontainer">
        <div className={styles.footerBody}>
          <div className={styles.footerBodyLinks}>
            <h4>Resources</h4>

            <div>
              <Link href="/newsletter">Newsletter</Link>
            </div>

            <div>
              <Link href="/articles">Articles</Link>
            </div>

            <div>
              <Link href="/guides/salary-negotiation">Salary negotiation guide</Link>
            </div>

            <div>
              <Link href="/offers">Offer review</Link>
            </div>

            <div>
              <Link href="/for-financial-institutions">For financial institutions</Link>
            </div>

            <div>
              <Link href="/about">About Candor</Link>
            </div>
            <div>
              <Link href="/security">Security</Link>
            </div>
          </div>
          <div className={styles.footerBodyArticles}>
            <h4>Trending articles</h4>
            <ArticleList articles={trendingArticles.slice(0, 3)} />
          </div>
        </div>

        <div className={styles.footerEmail}>
          <div className={styles.footerEmailText}>Join the newsletter for top tech professionals</div>
          <EmailForm event="Newsletter sign up" data={{ source: "footer" }} />
        </div>

        <FooterDisclosures />
      </div>
    </footer>
  )
}
