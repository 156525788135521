import clsx from "clsx"
import { ReactNode } from "react"
import styles from "./Pill.module.css"

type Props = {
  title: string | ReactNode
  actionable?: {
    onChange: (index) => void
    active: boolean
  }
  color?: "green" | "grey"
}

const Pill = ({ title, actionable, color }: Props) => {
  return (
    <div
      className={clsx(
        styles.container,
        actionable && styles.actionable,
        actionable?.active && styles.active,
        color === "grey" ? styles.grey : styles.green
      )}
      onClick={actionable ? actionable.onChange : undefined}
    >
      {title}
    </div>
  )
}

export default Pill
