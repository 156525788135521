import ToggleOff from "vector/icons/toggle-off.svg"
import ToggleOn from "vector/icons/toggle-on.svg"
import styles from "./Toggle.module.css"

type ToggleProps = {
  enabled: boolean
  dataCy?: string
  onClick: () => void
}

export const Toggle = ({ enabled, onClick, dataCy }: ToggleProps) => {
  return enabled ? (
    <ToggleOn className={styles.toggleOn} onClick={onClick} data-cy={dataCy ? `${dataCy}-on` : undefined} />
  ) : (
    <ToggleOff className={styles.toggleOff} onClick={onClick} data-cy={dataCy ? `${dataCy}-off` : undefined} />
  )
}
