import clsx from "clsx"
import React, { useEffect, useState } from "react"
import styles from "./ProgressBar.module.css"

interface Props {
  active?: boolean
  progress?: number // 0-100
  height?: string
  disabled?: boolean
  gradientVersion?: "rainbow" | "dark" | "gradientOnboarding" | "greenOnboarding" | "greenDashboard"
  transformTime?: number
  insideText?: string
  outsideText?: string
  containerClass?: string
  barClass?: string
}

export const ProgressBar = ({
  active,
  progress,
  height,
  disabled,
  gradientVersion,
  transformTime,
  insideText,
  outsideText,
  containerClass,
  barClass,
}: Props) => {
  const [fullWidth, setFullWidth] = useState("0")
  useEffect(() => {
    setTimeout(() => {
      setFullWidth("100")
    }, 10)
  }, [])
  const width = (progress && progress < 101) || progress === 0 ? progress : "100"
  return (
    <div className={clsx(getContainerStyle(gradientVersion), containerClass)}>
      <div
        className={getBarStyle(active, progress, disabled, gradientVersion, barClass)}
        style={
          {
            width: `${transformTime ? fullWidth : width}%`,
            height: height ? height : undefined,
            "--transform-time": `${transformTime}ms`,
          } as React.CSSProperties
        }
      >
        {insideText && <span className={styles.insideText}>{insideText}</span>}
      </div>
      {outsideText && <span className={progress > 0 ? styles.outsideTextAlt : styles.outsideText}>{outsideText}</span>}
    </div>
  )
}

function getContainerStyle(gradientVersion?: Props["gradientVersion"]) {
  if (gradientVersion) {
    switch (gradientVersion) {
      case "rainbow":
        return clsx(styles.container, styles.containerRainbow)
      case "dark":
        return clsx(styles.container, styles.containerDark)
      case "gradientOnboarding":
        return clsx(styles.container)
      case "greenOnboarding":
        return clsx(styles.container)
      case "greenDashboard":
        return clsx(styles.container, styles.containerGreenDashboard)
      default:
        return clsx(styles.container)
    }
  }
  return clsx(styles.container)
}

function getBarStyle(
  active?: boolean,
  progress?: number,
  disabled?: boolean,
  gradientVersion?: Props["gradientVersion"],
  barClass?: string
) {
  if (disabled) {
    return clsx(styles.bar, styles.disabled, barClass)
  }
  if (gradientVersion) {
    switch (gradientVersion) {
      case "rainbow":
        return clsx(styles.bar, styles.rainbow, barClass)
      case "dark":
        return clsx(styles.bar, styles.dark, barClass)
      case "gradientOnboarding":
        return clsx(styles.bar, styles.gradientOnboarding, barClass)
      case "greenOnboarding":
        return clsx(styles.bar, styles.greenOnboarding, barClass)
      case "greenDashboard":
        return clsx(styles.bar, styles.greenDashboard, barClass)
    }
  }
  if (active) {
    if (progress === 100) {
      return clsx(styles.bar, styles.activeFull, barClass)
    }
    return clsx(styles.bar, styles.active, barClass)
  }
  return clsx(styles.bar, barClass)
}
