import clsx from "clsx"
import styles from "./RadioInput.module.css"

type Props = {
  value: boolean
  onClick: () => void
  className?: string
  name?: string
  id?: string
  disabled?: boolean
  "data-cy"?: string
}

// TODO: this is not up to spec - we should have a label that's clickable here
export const RadioInput = ({ value, onClick, className, name = "radio", id, disabled = false, ...props }: Props) => {
  return (
    <label className={clsx(styles.radioContainer, className)} htmlFor={id}>
      <input type="radio" name={name} onChange={onClick} checked={value} disabled={!!disabled} id={id} {...props} />
      <span className={styles.checkmark}></span>
    </label>
  )
}
