import Link from "next/link"
import { useRef } from "react"

import imageUrlBuilder from "@sanity/image-url"
import Router from "next/router"
import { gsap } from "gsap/dist/gsap"

import useIsomorphicLayoutEffect from "components/hooks/useIsomorphicLayoutEffect"
import styles from "./ArticleList.module.css"

function ArticleList({ articles, initialArticlesLimit = undefined, className = "" }) {
  const listRef = useRef(null)

  const imageBuilder = imageUrlBuilder({
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET,
  })

  useIsomorphicLayoutEffect(() => {
    listRef.current.querySelectorAll(".card")?.forEach((item, index) => {
      const animationIndex = index >= initialArticlesLimit ? index - initialArticlesLimit : index
      gsap.to(item, {
        opacity: 1,
        duration: 0.7,
        delay: animationIndex * 0.1,
      })
    })
  }, [articles])

  return (
    <div ref={listRef} className={`${styles.articleList} ${className}`}>
      {articles.map((article) =>
        article ? (
          <div
            className={`card ${styles.articleCard}`}
            key={article.slug.current}
            onClick={() => Router.push(`/articles/${article.category.slug.current}/${article.slug.current}`)}
          >
            <div
              className={styles.articleCardImage}
              style={{
                background: `url(${
                  article.bannerImage && `${imageBuilder.image(article.bannerImage).width(333).url()}`
                })`,
              }}
            />
            <div className={styles.articleCardTitle}>
              <h3>{article.title}</h3>
              <Link
                href={`/articles/${article.category.slug.current}/${article.slug.current}`}
                className={styles.articleCardLink}
              >
                See More ›
              </Link>
            </div>
          </div>
        ) : null
      )}
    </div>
  )
}

export default ArticleList
