import React, { useState } from "react"
import NumberFormat from "react-number-format"
import clsx from "clsx"
import styles from "./Input.module.css"

import CheckMarkIcon from "../../../../vector/icons/checkmark.svg"
import EyeVisibleIcon from "../../../../vector/icons/eye-visible.svg"
import EyeHiddenIcon from "../../../../vector/icons/eye-hidden.svg"
import { Space } from ".."

interface TextType extends React.InputHTMLAttributes<HTMLInputElement> {
  type: "text"
}

interface EmailType extends React.InputHTMLAttributes<HTMLInputElement> {
  type: "email"
}

interface NumberType extends React.InputHTMLAttributes<HTMLInputElement> {
  type: "number"
  className?: string
  min?: number
  max?: number
}

interface PasswordType extends React.InputHTMLAttributes<HTMLInputElement> {
  type: "password"
  enableHide?: boolean
}

interface TextAreaType extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  type: "textarea"
}

interface PhoneNumberType {
  type: "phoneNumber"
  value: any
  className?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

interface PercentageType {
  type: "percentage"
  value: any
  onValueChange: any
  className?: string
  disabled?: boolean
  overrideStyle?: boolean
}

interface SelectType extends React.SelectHTMLAttributes<HTMLSelectElement> {
  type: "select"
  options: (
    | string
    | { key: string | number; value?: string | number; name: string | number; disabled?: boolean | null }
  )[]
  width?: number | string
  className?: string
  noValueText?: string
  placeholder?: string
}

interface RadioType extends React.InputHTMLAttributes<HTMLInputElement> {
  type: "radio"
  handleChange: () => void
}

interface CheckBoxType extends React.InputHTMLAttributes<HTMLInputElement> {
  type: "checkbox"
  handleChange: (checked: boolean) => void
  label?: string | React.ReactNode
  subLabel?: string
  disableLabelOnClick?: boolean
}

interface DollarValueType extends React.InputHTMLAttributes<HTMLInputElement> {
  type: "dollarValue"
  onValueChange: (value: number) => void
  value: number
}

type Props =
  | TextType
  | NumberType
  | EmailType
  | PasswordType
  | SelectType
  | CheckBoxType
  | PhoneNumberType
  | RadioType
  | TextAreaType
  | PercentageType
  | DollarValueType

const SelectInput = (props: SelectType) => {
  const { noValueText, options, ...rest } = props

  return (
    <select
      {...rest}
      style={{ width: props.width ?? null }}
      className={clsx(
        props.className,
        styles.input,
        styles.select,
        !props.width && styles.selectWidthAuto,
        typeof props.width === "number" && props.width > 250 && styles.selectWide,
        props.className,
        rest.value === "" && styles.selectPlaceholder
      )}
      value={props.value}
    >
      <option key={"please-select"} value={""} disabled>
        {props.noValueText ? props.noValueText : "Please select"}
      </option>
      {options?.map((option) => {
        if (typeof option === "string") {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          )
        } else {
          return (
            <option
              key={clsx(option.key, option.name)}
              value={option.value ? option.value : option.key}
              disabled={option.disabled}
            >
              {option.name}
            </option>
          )
        }
      })}
    </select>
  )
}

const RadioInput = (props: RadioType) => {
  const { checked = false } = props
  const { handleChange: handleChangeProp, ...sanitizedProps } = props

  const handleChange = () => handleChangeProp()

  return (
    <div className={clsx(styles.radioInput, checked && styles.selected)}>
      <input type="radio" {...sanitizedProps} onClick={handleChange} onChange={handleChange} />
      <div></div>
    </div>
  )
}

// this is not right - it's not <input> element so it won't properly work with forms
const CheckBoxInput = (props: CheckBoxType) => {
  const { checked = false, className } = props
  const checkedState = checked ? styles.checked : styles.unchecked

  const handleChange = () => {
    props.handleChange(!checked)
  }

  // Remove handleChange from props to prevent it from being passed to the input
  const { handleChange: _, disableLabelOnClick, label, subLabel, ...sanitizedProps } = props
  return (
    <Space
      direction="row"
      onClick={disableLabelOnClick ? undefined : handleChange}
      className={styles.checkMarkContainer}
    >
      <div
        {...sanitizedProps}
        className={clsx(className, checkedState)}
        onClick={disableLabelOnClick ? handleChange : undefined}
      >
        {checked && (
          <div className={styles.checkMarkIconContainer}>
            <CheckMarkIcon className={styles.checkMarkIcon} />
          </div>
        )}
      </div>

      {label ? (
        <div>
          {typeof label === "string" ? <div className={styles.labelTitle}>{label}</div> : label}
          {subLabel ? <div className={styles.subLabelTitle}>{subLabel}</div> : undefined}
        </div>
      ) : undefined}
    </Space>
  )
}

export const Input = (props: Props) => {
  const [passwordHidden, setPasswordHidden] = useState(true)
  const onEyeClick = () => setPasswordHidden(!passwordHidden)
  switch (props.type) {
    case "text":
    case "email":
    case "number":
      return <input style={{ width: props.width ?? null }} {...props} className={clsx(styles.input, props.className)} />
    case "password":
      const { enableHide, ...rest } = props
      return (
        <div className={styles.password}>
          <input
            {...rest}
            type={passwordHidden ? "password" : "text"}
            style={{ width: props.width ?? null }}
            className={clsx(styles.input, props.className)}
          />
          {enableHide &&
            (passwordHidden ? <EyeVisibleIcon onClick={onEyeClick} /> : <EyeHiddenIcon onClick={onEyeClick} />)}
        </div>
      )
    case "textarea":
      return <textarea {...props} className={clsx(styles.textArea, props.className)} />
    case "phoneNumber":
      return (
        <NumberFormat
          placeholder="XXX-XXX-XXXX"
          format="###-###-####"
          {...props}
          type="tel"
          className={clsx(styles.input, styles.phoneNumber, props.className)}
        />
      )
    case "percentage":
      const { overrideStyle, ...restPercentageProps } = props

      return (
        <NumberFormat
          placeholder="0"
          max={100}
          {...restPercentageProps}
          type="text"
          value={props.value}
          className={overrideStyle ? clsx(styles.input, props.className) : clsx(styles.percentage, props.className)}
          allowNegative={false}
          isAllowed={(values) => {
            const { formattedValue } = values
            return !formattedValue.includes(",") && !formattedValue.includes(".")
          }}
          onValueChange={(values) => {
            values?.floatValue > 100
              ? props.onValueChange({ floatValue: 100, formattedValue: "100,00", value: "100.0" })
              : props.onValueChange(values)
          }}
          disabled={props.disabled}
        />
      )
    case "select":
      return <SelectInput {...props} />
    case "checkbox":
      return <CheckBoxInput {...props} />
    case "radio":
      return <RadioInput {...props} />
    case "dollarValue":
      return (
        <NumberFormat
          placeholder="$0"
          type="text"
          prefix="$"
          value={props.value || null}
          className={clsx(styles.input, props.className)}
          allowNegative={false}
          onValueChange={(values) => props.onValueChange(values.floatValue)}
          disabled={props.disabled}
        />
      )
  }
}
