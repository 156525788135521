import { useState } from "react"

import analytics from "helpers/analytics"
import styles from "./EmailForm.module.css"

type Props = {
  event: any
  data?: any
  onSubmit?: any
}

function EmailForm({ event, data = {}, onSubmit }: Props) {
  const [formState, setFormState] = useState(null)
  const [email, setEmail] = useState(null)
  const [responseContent, setResponseContent] = useState(null)

  async function handleSubmit(e) {
    e.preventDefault()
    setFormState("loading")

    if (email) {
      const trimmedEmail = email.trim()
      analytics.track(event, { user: { email: trimmedEmail }, eventProperties: data })

      let successText

      switch (event) {
        case "Performance guide download":
          successText = "Check your inbox — the toolkit is on the way."
          break
        case "Salary guide download":
          successText = "Check your inbox — the PDF guide is on the way."
          break
        case "Newsletter sign up":
          successText = "You're signed up to receive the next newsletter."
          break
        case "Recruiter guide download":
          successText = "Check your inbox - your email templates are on the way."
          break
        case "Webinar signup":
          successText = "Look out for an email this week with details."
          break
        case "Downloadable requested":
          successText = "Look out for an email with the download link."
          break
        default:
          successText = "Successfully registered your email"
      }

      setFormState(null)
      setResponseContent({ text: successText, class: "success" })

      if (onSubmit) {
        onSubmit()
      }
    } else {
      setFormState(null)
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form} method="POST">
      <input
        name="email"
        type="email"
        placeholder="you@example.com"
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="submit"
        className={`button dark ${formState === "loading" ? "disabled" : ""}`}
        value={formState === "loading" ? "Submitting…" : "Submit"}
      />
      {responseContent && (
        <div className={`${styles.formResponse} ${responseContent.class}`}>{responseContent.text}</div>
      )}
    </form>
  )
}

export default EmailForm
