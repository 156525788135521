import { ReactNode, useState } from "react"
import styles from "./ExpandableText.module.css"

type Props = {
  children: ReactNode
  className?: string
  isAlsoEnabledOnDesktop?: boolean // By default we only shorten the text on mobile
}

export const ExpandableText = ({ children, className = "", isAlsoEnabledOnDesktop = false }: Props) => {
  const [textExpanded, setTextExpanded] = useState(false)
  return (
    <div
      className={`${styles.container} ${className} ${isAlsoEnabledOnDesktop ? styles.desktopEnabled : ""} ${
        textExpanded ? styles.expanded : ""
      }`}
    >
      {children}
      {textExpanded ? null : (
        <span className={styles.expandLink} onClick={() => setTextExpanded(true)}>
          Show more
        </span>
      )}
    </div>
  )
}
