import React from "react"
import clsx from "clsx"
import styles from "./Switch.module.css"

interface Props {
  values: string[]
  checked: number
  onChange(index: number): void
}

export const Switch = ({ values, checked, onChange: onClick }: Props) => {
  return (
    <div className={styles.container}>
      {values.map((e, idx) => (
        <div key={e} className={clsx(styles.option, idx === checked && styles.checked)} onClick={() => onClick(idx)}>
          {e}
        </div>
      ))}
    </div>
  )
}
