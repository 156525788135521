import { useState } from "react"
import clsx from "clsx"
import UpIcon from "vector/icons/up.svg"
import styles from "./Drawer.module.css"

interface Props {
  title: string | JSX.Element
  bodyComponent: string | JSX.Element
}

interface DrawerHeaderProps {
  title: string | JSX.Element
  isOpen: boolean
  onExpand: () => void
}

const DrawerHeader = (props: DrawerHeaderProps) => {
  const { title, onExpand, isOpen } = props

  return (
    <div className={clsx(styles.drawer, isOpen && styles.drawerExpanded)} onClick={onExpand}>
      <div className={styles.iconWrapper}>
        <UpIcon className={clsx(styles.icon, !isOpen && styles.iconReversed)} />
      </div>
      <div className={styles.titleHolder}>{title}</div>
    </div>
  )
}

export const Drawer = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={styles.sectionHolder}>
      <DrawerHeader {...props} onExpand={() => setIsOpen(!isOpen)} isOpen={isOpen} />
      {isOpen && <div className={styles.drawerContentHolder}>{props.bodyComponent}</div>}
    </div>
  )
}
