import clsx from "clsx"
import StarIcon from "vector/icons/star.svg"
import styles from "./Star.module.css"

type Props = {
  className?: string
  isSection16?: boolean
}

export const Star = ({ className, isSection16 }: Props) => {
  return <StarIcon className={clsx(className, styles.starIcon, isSection16 && styles.starIconSec16)} />
}
