import React, { ReactNode } from "react"
import styles from "./LoadingWrapper.module.css"

interface Props {
  isLoading: boolean
  children?: ReactNode
  className?: string
  inline?: boolean
  width?: number | string
  height?: number | string
  noRenderWhileLoading?: boolean
  flex?: boolean
  solid?: boolean
  "data-cy"?: string
  onClick?: () => void
}

export const LoadingWrapper = (props: Props) => {
  const {
    isLoading,
    children,
    className = "",
    inline,
    width,
    height,
    flex = false,
    noRenderWhileLoading = false,
    solid = false,
    onClick = null,
  } = props

  return (
    <div
      className={`${isLoading ? styles.loaderWrapper : ""} ${isLoading && solid ? styles.solid : ""} ${className} ${
        flex ? styles.flex : ""
      }`}
      style={{
        display: inline ? "inline-flex" : "flex",
        width: isLoading ? width : undefined,
        height: isLoading ? height : undefined,
      }}
      onClick={() => (isLoading || !onClick ? null : onClick())}
      data-cy={props["data-cy"]}
    >
      {(!noRenderWhileLoading || !isLoading) && children}
    </div>
  )
}
