import { useEffect, ReactNode } from "react"
import { useUser } from "@clerk/nextjs"

import analytics from "helpers/analytics"
import Header from "./Header"
import Footer from "./Footer"

type Props = {
  children: ReactNode
  bodyClassName?: string
  theme?: "white" | "black"
  footerClassName?: string
  showHeader?: boolean
  showFooter?: boolean
}

const Layout = ({
  children,
  bodyClassName,
  footerClassName,
  showHeader = true,
  showFooter = true,
  theme = "white",
}: Props) => {
  const { user } = useUser()

  useEffect(() => {
    if (user && !user.publicMetadata.is_impersonating_user) {
      analytics.identify(user)
    }
  }, [user])

  // a little hacky
  useEffect(() => {
    if (document) {
      document.body.className = bodyClassName || ""
    }

    // cleanup
    return () => {
      if (document) {
        document.body.className = ""
      }
    }
  }, [bodyClassName])

  // log current page as landingPage for analytics
  useEffect(() => {
    if (!window.localStorage?.getItem("landingPage")) {
      window.localStorage?.setItem("landingPage", window.location.pathname)
    }
  }, [])

  return (
    <>
      {showHeader ? <Header theme={theme} /> : null}

      {children}

      {showFooter ? <Footer className={footerClassName} /> : null}
    </>
  )
}

export default Layout
